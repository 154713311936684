.headContent {
  background: rgb(4, 4, 68);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
h1 {
  color: rgb(194, 34, 34);
  transition: 0.5s;
  margin: 0px;
}
img {
  width: 150px;
  transition: 0.5s;
}
@media screen and (max-width: 650px) {
  .headContent h1 {
    font-size: 30px;
    transition: 0.5s;
  }
  .headContent img {
    width: 100px;
    transition: 0.5s;
  }
}
p {
  color: aliceblue;
}
