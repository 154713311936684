.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
  flex-direction: column;
}
.browse-file {
  border: black solid 1px;
  padding: 100px;
  background: white;
  width: 100%;
  font-size: 20px;
  cursor: pointer;
  transition: 0.5s;
  border-radius: 30px;
  display: flex;
  justify-content: center;
}
.browse-file:hover,
.on-hover {
  background: rgb(221, 220, 220);
  border: none;
  transition: 0.5s;
  border-radius: 30px;
}

.downloadFile {
  display: flex;
  border: none;
  background: rgb(194, 34, 34);
  border-radius: 20px;
  color: white;
  font-size: 20px;
  font-weight: bolder;
  padding: 25px;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 40px;
  cursor: pointer;
  transition: 0.5s;
}
.downloadFile:hover {
  transform: scale(1.1);
  transition: 0.5s;
}
.progressBar {
  transition: 0.5s;
}

@media screen and (max-width: 1350px) {
  .browse-file {
    padding: 100px 255px;
    transition: 0.5s;
  }
}
@media screen and (max-width: 900px) {
  .container {
    margin-left: 80px;
  }
}
@media screen and (max-width: 660px) {
  .browseFile {
    padding: 100px 55px;
    transition: 0.5s;
  }
}
